import { useEffect, useRef, useState } from "react";
import API from "../Support/API";
import { APIProgress, LinearProgress } from "../Components/Basics";
import { TbMoodEmpty } from "react-icons/tb";
import Main from "../Components/Main";
import { RiDeleteBin7Line, RiPencilLine } from "react-icons/ri";
import dateFormatter from "dateformat";
import { H3, Link, SmallButton, Button } from "../Components/Basics";
import { GreenBadge } from "../Components/Badges";
import {HiChatBubbleOvalLeft} from 'react-icons/hi2';
import DeleteWPSiteModal from "../Components/Modals/DeleteWPSiteModal";
const WPSites = () => {

    const allowPg = useRef(true);
    const [data, setData] = useState(null);
    const pageNo = useRef(1);
   

    useEffect(() => {
        getChats(pageNo.current);
    }, []);

    const getChats = (page) => {

        if (!allowPg.current) return;

        allowPg.current = false;

        let moreData = true;

        API.get(`wp-sites/page/${page}`)
        .then((e) => {

            if (e.data.length === 0) {

              setData((d) => {
                if(d === null) return [];
                else {
                    moreData = false;
                    return d;
                };
              });

            }
            else{

                const prompts = e.data;

                setData((d) => {
                    if(d === null) return prompts
                    else return [...d,...prompts];
                });

            }
        })
        .catch((err) => {
            setData(false);
            console.log(err);
        })
        .finally(() => {

            //if no more data is left
            if(moreData){
                allowPg.current = true;
            }
            else{
                allowPg.current = false;
            }
        })

    }

    

    useEffect(() => {

        const handleScroll = () => {
            const windowHeight = window.innerHeight;
            const documentHeight = document.documentElement.scrollHeight;
            const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;

            // Check if the user has scrolled to the bottom (with a little buffer)
            const buffer = 150; // Adjust this value as needed
            const isAtBottom = documentHeight - (windowHeight + scrollTop) <= buffer;


            if (isAtBottom) {

                if(allowPg.current){
                    pageNo.current += 1;
                }

                getChats(pageNo.current);
            }
        }

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };

    }, []);


    const formatNum = (number) => {

        if (number >= 1000000) {
            return (number / 1000000).toFixed(1) + 'M';
        } else if (number >= 1000) {
            return (number / 1000).toFixed(1) + 'K';
        } else {
            return parseInt(number)
        }
    }

    const dateFormat = (date) => {

        const now = new Date(date);

        const newDate = dateFormatter(now, "mmmm dd, yyyy");

        return newDate;
    }

    const [showDltModal, setShowDltModal] = useState(0);
    const [dltId, setDltId] = useState("");

    const dlt = (id) => {

        setDltId(id);
        setShowDltModal((showModal) => {
            return showModal + 1;
        });

    }

    const removeElement = (id) => {

        setData((data) => {

            return data.filter((e) => e.id !== id);

        });
    };

    return (

        <Main active="wpSites">

            <DeleteWPSiteModal id={dltId} show={showDltModal} removeFunc={removeElement} />

            <div className="flex items-center justify-between mb-5 mx-3 mt-3">

                <H3>
                    Wordpress Websites
                </H3>

                <Link to={"/wp-sites/add"}>
                    <Button>
                        Add New
                    </Button>
                </Link>


            </div>



            {

                data === null ?

                    <APIProgress /> :

                    data === false ?

                        <div className="c-height w-full items-center flex justify-center flex-col">

                            <TbMoodEmpty className="my-3 text-blue-600" size={50} />

                            <p class="text-base text-gray-900 dark:text-white text-center">Something went wrong. please try later.</p>


                        </div>
                        :

                        data !== null && data.length === 0 ?

                            <div className="c-height w-full items-center flex justify-center flex-col">

                                <TbMoodEmpty className="my-3 text-green-500" size={50} />

                                <p class="text-base text-gray-900 dark:text-white text-center">No Website found</p>


                            </div>

                            :

                            <>

                                <div className="relative overflow-x-auto px-2">

                                    <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">

                                        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                            <tr>
                                                <th scope="col" className="px-6 py-3">
                                                    Name
                                                </th>
                                                <th scope="col" className="px-6 py-3">
                                                    URL
                                                </th>
                                                <th scope="col" className="px-6 py-3">
                                                    Modified At
                                                </th>
                                                <th scope="col" className="px-6 py-3">
                                                    Action
                                                </th>
                                            </tr>
                                        </thead>

                                        <tbody>

                                            {

                                                data !== null && data.map((e) => {

                                                    //if status is training, increase on training value

                                                    return (
                                                        <tr key={e.id} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                                            <th scope="row" className="px-6 py-4 font-medium text-gray-900 dark:text-white">
                                                                {e.name}
                                                            </th>

                                                            <td className="px-6 py-4">
                                                                <a target="_blank" href={e.url}><span className={`bg-green-100 text-green-800 text-sm mr-2 px-2.5 py-1 rounded-full dark:bg-green-900 dark:text-green-300 whitespace-nowrap`}>{e.url}</span></a>
                                                            </td>

                                                           
                                                            <td className="px-6 py-4">
                                                                {dateFormat(e.updatedAt)}
                                                            </td>

                                                            <td className="px-6 py-4">

                                                                <div className="flex">

                                                                    <Link to={"/wp-sites/edit/" + e.id}><RiPencilLine color="#49a06f" className="mr-2" size={20} /></Link>

                                                                    <RiDeleteBin7Line size={20} onClick={() => dlt(e.id)} color="red" />

                                                                </div>

                                                            </td>


                                                        </tr>
                                                    )

                                                })
                                            }



                                        </tbody>

                                    </table>
                                </div>
                            </>
            }

        </Main>
    );
}

export default WPSites;