
import { RiLock2Line, RiImage2Line, RiMoneyDollarCircleLine,RiArticleLine, RiShareForwardLine, RiTranslate2, RiSearchEyeLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import Constants from "../Support/Constants";
import logo from "../Images/logo.png";
import { useState } from "react";
import { useEffect } from "react";
import { Button, DarkModeButton, FiveStars } from "../Components/Basics";
import CreditTable from "../Components/CreditTable";
import API from "../Support/API";
import { useNavigate } from "react-router-dom";

const Landing = () => {

    const [isDarkMode, setDarkMode] = useState(() => {

        if (localStorage.getItem("dark") === "true") {
            document.documentElement.classList.add("dark");
            return true;
        } else {
            return false;
        }
    });

    const navigate = useNavigate();

    const toggleDarkMode = (x) => {
        localStorage.setItem("dark", (x === true ? 'false' : 'true'));
        setDarkMode((x) => !x);
        document.documentElement.classList.toggle("dark");
    };

    const [creditPacks, setCreditPacks] = useState([]);

    const [lifetimePacks, setLifetimePacks] = useState([]);


    useEffect(() => {

        API.get('credits-packs')
            .then((e) => {

                setCreditPacks(e.data.creditPacks)

                setLifetimePacks(e.data.lifetimePacks)


            })
            .catch((err) => {

            });

    }, []);

    const onCreditPackClick = () => {
        navigate("/register");
    }

    return (

        <>
            <nav className="w-full bg-white dark:bg-gray-900 z-20 border-b border-gray-200 dark:border-gray-600">
                <div className="max-w-screen-2xl flex flex-wrap items-center justify-between mx-auto p-4">
                    <div className="flex items-center">
                        <Link to={Constants.homeUrl} className="mt-1 flex items-center">
                            <img src={logo} className="dark:invert dark:brightness-0" width={150} height={10} />
                            {/* <span className="self-center text-lg lg:text-2xl sm:text-xl font-semibold whitespace-nowrap dark:text-white">{Constants.appName}</span> */}
                        </Link>
                    </div>
                    <div className="block">
                        <div className="flex">
                            <ul className="font-medium flex flex-col mr-3 p-0 mt-4 border border-gray-100 rounded-lg bg-gray-50 flex-row space-x-8 md:mt-0 border-0 bg-white dark:bg-gray-800 dark:bg-gray-900 dark:border-gray-700">
                                <li>
                                    <a href="#pricing" className="hidden md:block text-green-500 rounded text-green-500 p-0 dark:text-green-300" aria-current="page">Pricing</a>
                                </li>


                            </ul>
                            <DarkModeButton
                                isDarkMode={isDarkMode}
                                onClick={() => toggleDarkMode(isDarkMode)}
                            />
                        </div>
                    </div>

                </div>
            </nav>

            <div className="main-landing px-6 py-0 md:py-6 flex flex-col items-center bg-gradient-to-b from-emerald-400 via-green-400 to-green-500 dark:bg-none dark:bg-gray-800 h-[600px] sm:h-[450px] md:h-[600px] lg:h-[700px]">

                <div className="max-w-6xl mx-auto md:pt-5">

                    <h1 class="mb-4 text-center mt-20 text-white text-4xl font-extrabold leading-none tracking-tight text-gray-900 md:text-5xl lg:text-6xl">
                        AI-Driven Content Creation Made Easy
                    </h1>
                    <p className="max-w-4xl mx-auto mb-3 text-white lg:text-xl text-center dark:text-gray-400 mt-5">
                        Unlock the Power of AI to effortlessly produce high-quality articles in bulk and seamlessly upload them to your WordPress website. Say goodbye to manual content creation and hello to efficiency, creativity, and innovation.
                    </p>
                </div>

                <div className="my-5">
                    <Link to={'/login'}><button type="button" className="text-white font-medium rounded-xl text-sm bg-green-500 hover:bg-green-600 lg:text-lg px-5 py-2.5 text-center">Get Started</button></Link>
                </div>

            </div>

            <div id="features">
                <h3 class="mb-6 dark:text-white text-center text-black text-2xl font-extrabold leading-none tracking-tight text-gray-900 md:text-3xl lg:text-4xl">
                    Features
                </h3>

                <div className="max-w-6xl mx-auto mt-12 flex justify-center md:px-5 md:justify-between flex-wrap items-center">

                    <div className="flex items-center sm:mr-5 mb-10">
                        <div className="mr-5 p-4 bg-gray-100 dark:bg-gray-800 rounded-lg">
                            <RiArticleLine class="dark:text-white" size={25} />
                        </div>
                        <div className="flex flex-col justify-center">
                            <h5 class="mb-1 text-gray-800 dark:text-gray-300 text-lg font-medium leading-none tracking-tight md:text-xl lg:text-xl">
                                Generate Articles In Bulk
                            </h5>
                            <p className="mb-3 text-gray-600 text-sm dark:text-gray-400 w-64">
                                Upload CSV with keywords to generate high quality bulk articles
                            </p>
                        </div>
                    </div>

                    <div className="flex items-center sm:mr-5 mb-10">
                        <div className="mr-5 p-4 bg-gray-100 dark:bg-gray-800 rounded-lg">
                            <RiSearchEyeLine class="dark:text-white" size={25} />
                        </div>
                        <div className="flex flex-col justify-center">
                            <h5 class="mb-1 text-gray-800 dark:text-gray-300 text-lg font-medium leading-none tracking-tight md:text-xl lg:text-xl">
                                SEO Optimzed Content
                            </h5>
                            <p className="mb-3 text-gray-600 text-sm dark:text-gray-400 w-64">
                                You'll always get SEO-optimized content, regardless of your keywords.
                            </p>
                        </div>
                    </div>

                    <div className="flex items-center sm:mr-5 mb-10">
                        <div className="mr-5 p-4 bg-gray-100 dark:bg-gray-800 rounded-lg">
                            <RiTranslate2 class="dark:text-white" size={25} />
                        </div>
                        <div className="flex flex-col justify-center">
                            <h5 class="mb-1 text-gray-800 dark:text-gray-300 text-lg font-medium leading-none tracking-tight md:text-xl lg:text-xl">
                                Multiple Language Support
                            </h5>
                            <p className="mb-3 text-gray-600 text-sm dark:text-gray-400 w-64">
                                Ability to understand and generate content in different languages
                            </p>
                        </div>
                    </div>

                    <div className="flex items-center sm:mr-5 mb-10">
                        <div className="mr-5 p-4 bg-gray-100 dark:bg-gray-800 rounded-lg">
                            <RiShareForwardLine class="dark:text-white" size={25} />
                        </div>
                        <div className="flex flex-col justify-center">
                            <h5 class="mb-1 text-gray-800 dark:text-gray-300 text-lg font-medium leading-none tracking-tight md:text-xl lg:text-xl">
                                Auto Post
                            </h5>
                            <p className="mb-3 text-gray-600 text-sm dark:text-gray-400 w-64">
                                Post generated contents directly to your wordpress website
                            </p>
                        </div>
                    </div>

                    <div className="flex items-center sm:mr-5 mb-10">
                        <div className="mr-5 p-4 bg-gray-100 dark:bg-gray-800 rounded-lg">
                            <RiImage2Line class="dark:text-white" size={25} />
                        </div>
                        <div className="flex flex-col justify-center">
                            <h5 class="mb-1 text-gray-800 dark:text-gray-300 text-lg font-medium leading-none tracking-tight md:text-xl lg:text-xl">
                                Auto Image Finder
                            </h5>
                            <p className="mb-3 text-gray-600 text-sm dark:text-gray-400 w-64">
                                Automatically find relevant copyright free images for your contents
                            </p>
                        </div>
                    </div>


                    <div className="flex items-center sm:mr-5 mb-10">
                        <div className="mr-5 p-4 bg-gray-100 dark:bg-gray-800 rounded-lg">
                            <RiMoneyDollarCircleLine class="dark:text-white" size={25} />
                        </div>
                        <div className="flex flex-col justify-center">
                            <h5 class="mb-1 text-gray-800 dark:text-gray-300 text-lg font-medium leading-none tracking-tight md:text-xl lg:text-xl">
                                Pay as you go
                            </h5>
                            <p className="mb-3 text-gray-600 text-sm dark:text-gray-400 w-64">
                                Buy credits only when you need. No hidden charges.
                            </p>
                        </div>
                    </div>

                </div>
            </div>

            <div id="pricing" className="my-12">
                <h3 class="mb-6 dark:text-white text-center text-black text-2xl font-extrabold leading-none tracking-tight text-gray-900 md:text-3xl lg:text-4xl">
                    Pricing
                </h3>

                <div className="max-w-6xl md:mx-auto mx-5 flex justify-center md:px-5 md:justify-between flex-wrap items-center">

                    {

                        creditPacks.map((e) => {

                            return (

                                <CreditTable

                                    id={e.id}
                                    name={e.name}
                                    credits={e.credits}
                                    days={e.expireDays}
                                    price={e.price}
                                    isLifeTime={false}
                                    onBtnClick={onCreditPackClick}


                                />
                            )

                        })

                    }


                </div>

            </div>


            {
                lifetimePacks.length > 0 &&


                <div id="pricing" className="my-12">
                    <h3 class="mb-6 dark:text-white text-center text-black text-2xl font-extrabold leading-none tracking-tight text-gray-900 md:text-3xl lg:text-4xl">
                        Lifetime Deals
                    </h3>

                    <div className="max-w-6xl md:mx-auto mx-5 flex justify-center md:px-5 md:justify-between flex-wrap items-center">

                        {

                            lifetimePacks.map((e) => {

                                return (

                                    <CreditTable

                                        id={e.id}
                                        name={e.name}
                                        credits={e.credits}
                                        days={e.expireDays}
                                        price={e.price}
                                        isLifeTime={true}
                                        onBtnClick={onCreditPackClick}


                                    />
                                )

                            })

                        }


                    </div>

                </div>

            }

            <div id="pricing" className="my-12">
                <h3 class="mb-8 mx-2 sm:mx-0 dark:text-white text-center text-black text-2xl font-extrabold leading-none tracking-tight text-gray-900 md:text-3xl lg:text-4xl">
                    See what our customers are saying
                </h3>

                <div className="max-w-6xl md:mx-auto mx-5 flex justify-center md:px-5 md:justify-between flex-wrap items-center">

                    <div className="flex items-center w-full md:w-[48%] lg:mr-3 mb-5 border border-slate-200 dark:border-gray-700 rounded-lg p-4 sm:p-6">
                        <div className="flex items-center flex-col w-36 sm:w-28">
                            <img src="https://i.ibb.co/4Y8z6d5/Screenshot-from-2023-10-19-17-14-49.png" className="w-16 rounded-full mb-2" />
                            <span className="text-xs text-gray-600 dark:text-gray-400">James Foster</span>
                            <div className="mt-2 flex">
                                <FiveStars />
                            </div>
                        </div>
                        <p className="ml-4 mb-3 text-gray-600 text-sm dark:text-gray-400 w-full">
                            This platform is a game-changer. I can generate bulk articles effortlessly using keywords or CSV files, and it posts them to my WordPress site flawlessly. A must for bloggers!
                        </p>
                    </div>

                    <div className="flex items-center w-full md:w-[48%] lg:mr-3 mb-5 border border-slate-200 dark:border-gray-700 rounded-lg p-4 sm:p-6">
                        <div className="flex items-center flex-col w-36 sm:w-28">
                            <img src="https://i.ibb.co/7YdCH8D/Screenshot-from-2023-10-19-17-11-07.png" className="w-16 rounded-full mb-2" />
                            <span className="text-xs text-gray-600 dark:text-gray-400">Rachel Roy</span>
                            <div className="mt-2 flex">
                                <FiveStars />
                            </div>
                        </div>
                        <p className="ml-4 mb-3 text-gray-600 text-sm dark:text-gray-400 w-full">
                            This tool streamlines my content creation and WordPress posting. AI-generated articles fit my niche perfectly. It's a must for content marketers.
                        </p>
                    </div>

                    <div className="flex items-center w-full md:w-[48%] lg:mr-3 mb-5 border border-slate-200 dark:border-gray-700 rounded-lg p-4 sm:p-6">
                        <div className="flex items-center flex-col w-36 sm:w-28">
                            <img src="https://i.ibb.co/ccf5qqC/Screenshot-from-2023-10-19-17-12-15.png" className="w-16 rounded-full mb-2" />
                            <span className="text-xs text-gray-600 dark:text-gray-400">Alex Harrison</span>
                            <div className="mt-2 flex">
                                <FiveStars />
                            </div>
                        </div>
                        <p className="ml-4 mb-3 text-gray-600 text-sm dark:text-gray-400 w-full">
                            Unbeatable integration with WordPress. Bulk article generation made easy. Ideal for WordPress users. A game-changer!
                        </p>
                    </div>

                    <div className="flex items-center w-full md:w-[48%] lg:mr-3 mb-5 border border-slate-200 dark:border-gray-700 rounded-lg p-4 sm:p-6">
                        <div className="flex items-center flex-col w-36 sm:w-28">
                            <img src="https://i.ibb.co/9sZ6Ld2/Screenshot-from-2023-10-19-17-13-25.png" className="w-16 rounded-full mb-2" />
                            <span className="text-xs text-gray-600 dark:text-gray-400">Sarah M</span>
                            <div className="mt-2 flex">
                                <FiveStars />
                            </div>
                        </div>
                        <p className="ml-4 mb-3 text-gray-600 text-sm dark:text-gray-400 w-full">
                            Saves time, maintains quality. Bulk content generation simplified. If you write, you need this tool. A big thank you to the creators
                        </p>
                    </div>






                </div>

            </div >



            <div className="max-w-6xl px-3 mt-16 bg-gray-800 py-20 rounded-3xl flex flex-col items-center justify-center md:mx-auto mx-5">
                <h1 class="mb-4 text-center text-white text-4xl font-extrabold leading-none tracking-tight text-gray-900 md:text-5xl lg:text-6xl">
                    So, how does it work?
                </h1>
                <div className="flex justify-center items-center mt-10 flex-wrap">
                    <div className="sm:mr-5 flex flex-col items-center justify-center">
                        <p className="mb-4 text-xl bg-gray-700 text-white px-4 py-2 rounded-xl">
                            1
                        </p>
                        <p className="mb-3 text-white text-center text-sm w-72">
                            Add your wordpress URL, username & app password
                        </p>
                    </div>

                    <div className="sm:mr-5 flex flex-col items-center justify-center">
                        <p className="mb-4 text-xl bg-gray-700 text-white px-4 py-2 rounded-xl">
                            2
                        </p>
                        <p className="mb-3 text-white text-center text-sm w-72">
                            Import keywords from CSV or enter manually. Select article style
                        </p>
                    </div>

                    <div className="sm:mr-5 flex flex-col items-center justify-center">
                        <p className="mb-4 text-xl bg-gray-700 text-white px-4 py-2 rounded-xl">
                            3
                        </p>
                        <p className="mb-3 text-white text-center text-sm w-72">
                            Save it. AI will start generating  and posting artcles
                        </p>
                    </div>



                </div>

            </div>


            <footer className="mt-20 z-20 w-full p-4 bg-white border-t border-gray-200 shadow md:flex md:items-center justify-center flex flex-col md:flex-row md:justify-between md:p-6 dark:bg-gray-800 dark:border-gray-600">
                <span className="text-sm mx-auto text-center sm:text-left sm:mx-0 text-gray-500 dark:text-gray-400">© 2023 <Link to="/" class="hover:underline">{Constants.appName}</Link>. All Rights Reserved.
                </span>
                <ul className="flex flex-wrap justify-center sm:justify-start items-center mt-3 text-sm font-medium text-gray-500 dark:text-gray-400 sm:mt-0">
                    <li>
                        <a href="#features" className="mr-4 hover:underline md:mr-6">Features</a>
                    </li>
                    <li>
                        <a href="#pricing" className="mr-4 hover:underline md:mr-6">Pricing</a>
                    </li>

                </ul>
            </footer>


        </>

    )

}

export default Landing;