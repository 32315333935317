import NavBarAuth from "../Components/NavBarAuth";
import { useRef, useState } from "react";
import { isEmail, isEmpty } from "../Support/FormValidator";
import { Button, H2, Input, Link } from "../Components/Basics";
import { useLocation, useNavigate, Navigate } from "react-router-dom";
import API from "../Support/API";
import Constants from "../Support/Constants";



const Login = ({isLoggedIn}) => {

    const location = useLocation();
    const navigate = useNavigate();

    const [routeData , setRouteData] = useState(location?.state?.msg ?? "");

    const formRef = useRef();

    const [errMsg, setErrMsg] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const onLogin = () => {

        //reset route msg
        setRouteData("");

        setErrMsg("");

        if (isEmpty(["email", "password"], formRef.current)) {
            setErrMsg("Please fill all the fields.");
            return;
        }

        const { email, password } = formRef.current;

        if (!isEmail(email.value)) {
            setErrMsg("Not a valid email");
            return;
        }

        setIsLoading(true);

        API.post("login", {
            email: email.value,
            password: password.value
        })
        .then((e) => {
           window.location.href = Constants.homeUrl;
        })
        .catch((err) => {
            setErrMsg(err.message);
            setIsLoading(false);
        });
    }



    return (

        isLoggedIn ?

         <Navigate to={Constants.homeUrl} />

        :

        <>
            <NavBarAuth />


            <div className="app-container px-2 flex justify-center flex-col items-center">


                <div className="border border-slate-300 dark:border-gray-600 px-10 py-20 rounded-lg w-full sm:w-full md:w-1/2 lg:w-1/3 xl:w-1/3 2xl:w-1/4">

                    <H2>
                        Sign In
                    </H2>

                    {
                        errMsg && <div className="mb-4"><span className="text-red-600">{errMsg}</span></div>
                    }

                    {
                        routeData && <div className="mb-4"><span className="text-blue-600 dark:text-blue-300">{routeData}</span></div>
                    }

                    <form ref={formRef}>

                        <Input

                            type="email"
                            label="Your Email"
                            name="email"

                        />

                        <Input

                            type="password"
                            label="Your Password"
                            name="password"

                        />

                        <div className="mb-4">
                           <Link to={'/forgot-password/link'}>Forgot Password ?</Link>
                        </div>

                        <Button isLoading={isLoading} onClick={onLogin} className="w-full">
                            Sign In
                        </Button>

                        <div className="my-5 dark:text-white">
                            <span className="mr-1">Don't have an account ?</span>
                            <Link to={"/register"}>
                                Sign Up
                            </Link>
                        </div>


                    </form>

                </div>


            </div>
        </>
    );
}

export default Login;