import Main from "../Components/Main";
import { Input, H3, H5, Button, SelectWithValue, TextArea, Checkbox } from "../Components/Basics";
import { useEffect, useRef, useState } from "react";
import { isEmpty } from "../Support/FormValidator";
import API from "../Support/API";
import { BackBox } from "../Components/Basics";
import { useNavigate } from "react-router-dom";

const AddList = () => {

    const formRef = useRef();

    const [errMsg, setErrMsg] = useState("");
    const [okMsg, setOkMsg] = useState("");
    const [isBtnLoading, setIsBtnLoading] = useState(false);
    const navigate = useNavigate();

    const [postToWP, setPostToWP] = useState(true);
    const [wpSites, setWPSites] = useState([{ title: '', value: '' }]);

    const [styles, setStyles] = useState([{ title: '', value: '' }]);

    const _postToWP = useRef(true);

    const onCheckboxChanged = () => {
        setPostToWP((e) => {
            _postToWP.current = !e;
            return !e;
        });
    }


    //fetch all wp sites
    useEffect(() => {

        API.get('wp-sites/all')
            .then((e) => {

                let tempOptions = [];

                for (const x of e.data) {
                    tempOptions.push({
                        value: x.id,
                        title: x.url
                    });
                }

                setWPSites(tempOptions);

                let tempStyles = [];

                for (const x of e.styles) {
                    tempStyles.push({
                        value: x.id,
                        title: x.name
                    });
                }

                setStyles(tempStyles);

            })
            .catch((err) => {

            });


    }, []);

    const onAdd = () => {

        setErrMsg("");
        setOkMsg("");

        const { name, articleLang, keywords, articleStyle, intervalMins } = formRef.current;

        setIsBtnLoading(true);

        let obj = {
            name: name.value,
            articleLang: articleLang.value,
            keywords: keywords.value,
            articleLang: articleLang.value,
            articleStyle: articleStyle.value,
            intervalMins: intervalMins.value,
            postToWP: _postToWP.current
        };

        if (_postToWP.current) {

            const { wpSiteId, wpCategory, wpStatus } = formRef.current;

            obj['wpSiteId'] = wpSiteId.value;
            obj['wpCategory'] = wpCategory.value;
            obj['wpStatus'] = wpStatus.value;
        };

        API.post("lists/add", obj)
            .then((e) => {


                setIsBtnLoading(false);

                navigate("/lists", { replace: true });

            })
            .catch((err) => {
                setErrMsg(err.message);
                setIsBtnLoading(false);
            });
    }

    //read csv
    function readCSVFile(file, callback) {
        const reader = new FileReader();

        reader.onload = function (e) {
            const contents = e.target.result;
            const lines = contents.split('\n');
            const firstColumnData = [];

            for (let i = 1; i < lines.length; i++) { // Start from index 1 to skip the header row
                const row = lines[i].split(',');
                if (row.length > 0) {
                    firstColumnData.push(row[0].trim());
                }
            }

            callback(firstColumnData);
        };

        reader.readAsText(file);
    }

    const handleFileChange = (event) => {
        const file = event.target.files[0];

        if(file){
            readCSVFile(file, (data) => {
                const {keywords} = formRef.current;
                
                for(const x of data){
                    if(x.length === 0) continue;
                    keywords.value += x + "\n";
                }

                keywords.value = keywords.value.trim();
              
            });
        }
        
    };

        return (

            <Main active="lists">

                {

                    <div className="p-4 lg:max-w-4xl lg:m-auto w-full">

                        <BackBox
                            path={'/lists'}
                            msg="Back To Lists"
                        />

                        <H3 className="mb-5 mt-5">
                            Add New List
                        </H3>

                        <form ref={formRef} className="w-full">


                            <Input

                                type="text"
                                label="Name"
                                name="name"
                                placeholder={"Artciles related to tech"}
                                important={true}

                            />

                            <div className="mb-6">
                                <label class="block mb-2 text-sm font-medium text-gray-900 dark:text-white" for="default_size">Upload CSV</label>
                                <input accept=".csv" onChange={handleFileChange} class="block w-full mb-5 text-sm text-gray-900 border border-gray-300 rounded cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400" type="file" />
                                <div className="dark:text-gray-400 text-gray-500 -mt-3 text-sm">Keywords must be in the first column of the CSV file. Our algorithm will only extract keywords from the first column.</div>
                            </div>


                            {/* <div className="dark:text-gray-400 text-gray-500 my-3 text-sm text-center w-full">OR</div> */}

                            <div className="flex items-center mb-5">
                                <div className="flex-1 border-t border-gray-500"></div>
                                <div className="mx-4 text-gray-500">OR</div>
                                <div className="flex-1 border-t border-gray-500"></div>
                            </div>

                            <TextArea

                                type="text"
                                label="Keywords"
                                name="keywords"
                                placeholder={"Cybersecurity\nMachine Learning"}
                                important={true}
                                rows={12}

                            />

                            <div className="dark:text-gray-400 text-gray-500 mb-6 text-sm -mt-3">One keyword per line</div>

                            <Input

                                type="text"
                                label="Article Language"
                                name="articleLang"
                                placeholder={"Spanish"}

                            />

                            <div className="dark:text-gray-400 text-gray-500 mb-6 text-sm -mt-3">Leave this empty for English Articles</div>


                            <SelectWithValue
                                label={"Article Style"}
                                name={'articleStyle'}
                                important={true}
                                options={styles}
                            />

                            <SelectWithValue
                                label={"Interval for each post"}
                                name={'intervalMins'}
                                important={true}
                                selectedValue={10}
                                options={[
                                    {
                                        value: 5,
                                        title: '5 Minutes'
                                    },
                                    {
                                        value: 10,
                                        title: '10 Minutes'
                                    },
                                    {
                                        value: 20,
                                        title: '20 Minutes'
                                    },
                                    {
                                        value: 30,
                                        title: '30 Minutes'
                                    },
                                    {
                                        value: 60,
                                        title: '1 Hour'
                                    },
                                    {
                                        value: 120,
                                        title: '2 Hours'
                                    },
                                    {
                                        value: 360,
                                        title: '6 Hours'
                                    },
                                    {
                                        value: 720,
                                        title: '12 Hours'
                                    },
                                    {
                                        value: 1440,
                                        title: '1 Day'
                                    },
                                    {
                                        value: 4320,
                                        title: '3 Days'
                                    },
                                    {
                                        value: 10080,
                                        title: '7 Days'
                                    },
                                ]}
                            />

                            <Checkbox

                                label='Post articles on selected wordpress website'
                                checked={true}
                                onChange={onCheckboxChanged}
                                name={'postToWP'}

                            />

                            <div className="dark:text-gray-400 text-gray-500 mb-6 text-sm -mt-1">If left unchecked, articles will not be published on any WordPress website, but they will be stored in the database. You can access these generated articles on the 'Article' page.</div>


                            {
                                postToWP === true &&


                                <div>

                                    <SelectWithValue
                                        label={"Wordpress Website"}
                                        name={'wpSiteId'}
                                        important={true}
                                        options={wpSites}
                                    />

                                    <Input

                                        type="text"
                                        label="Wordpress Category Name"
                                        name="wpCategory"
                                        important={true}

                                    />

                                    <div className="dark:text-gray-400 text-gray-500 mb-6 text-sm -mt-3">The spelling of the category name should be correct, letter case does not matter.</div>


                                    <SelectWithValue
                                        label={"Wordpress Post Status"}
                                        name={'wpStatus'}
                                        important={true}
                                        options={[
                                            {
                                                value: 'publish',
                                                title: 'Publish'
                                            },
                                            {
                                                value: 'draft',
                                                title: 'Draft'
                                            }
                                        ]}
                                    />

                                </div>

                            }


                            <div className="dark:text-gray-400 text-gray-500 mb-4 mt-7 text-sm -mt-3">Please review the details above carefully, as you won't be able to update them after adding, but you will have the option to pause or delete the list.</div>



                            {
                                errMsg && <div className="my-4"><span className="text-red-600">{errMsg}</span></div>
                            }

                            {
                                okMsg && <div className="my-4"><span className="text-green-600 dark:text-green-400">{okMsg}</span></div>
                            }


                            <Button onClick={onAdd} isLoading={isBtnLoading} className={"mt-2 w-full"}>
                                Add
                            </Button>

                        </form>


                    </div>

                }


            </Main>

        );


    }

    export default AddList;