export const DefaultBadge = ({text}) => {

    return (

        <span className="bg-blue-100 text-blue-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded-full dark:bg-blue-900 dark:text-blue-300">{text}</span>

    )

}

export const GrayBadge = ({text}) => {

    return (

        <span className="bg-gray-100 text-gray-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded-full dark:bg-gray-700 dark:text-gray-300">{text}</span>

    )

}

export const RedBadge = ({text}) => {

    return (

        <span className="bg-red-100 text-red-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded-full dark:bg-red-900 dark:text-red-300">{text}</span>

    )

}

export const GreenBadge = ({text, className}) => {

    return (

        <span className={`bg-green-100 text-green-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded-full dark:bg-green-900 dark:text-green-300 ${className}`}>{text}</span>

    )

}

export const YellowBadge = ({text}) => {

    return (

        <span className="bg-yellow-100 text-yellow-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded-full dark:bg-yellow-900 dark:text-yellow-300">{text}</span>

    )

}
