import Main from "../Components/Main";
import { Input, H3, H5, Button, BigProgress } from "../Components/Basics";
import { useEffect, useRef, useState } from "react";
import { isEmpty } from "../Support/FormValidator";
import API from "../Support/API";
import { BackBox } from "../Components/Basics";

const Profile = () => {

    const formRef = useRef();

    const [errMsg, setErrMsg] = useState("");
    const [okMsg, setOkMsg] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const [isBtnLoading, setIsBtnLoading] = useState(false);

    useEffect(() => {

        let to;

        API.get('profile')
            .then((e) => {

                setIsLoading(false);

                to = setTimeout(() => {
                    const { name, email } = formRef.current;

                    name.value = e.profile.name;
                    email.value = e.profile.email;
                   

                }, 100);
            });

        return () => to !== null ? clearTimeout(to) : ""

    }, []);

    const onUpdate = () => {

        setErrMsg("");
        setOkMsg("");

        if (isEmpty(["name"], formRef.current)) {
            setErrMsg("Please write the name");
            return;
        }

        const { name, password, newPassword, confirmPassword, } = formRef.current;


        if (password.value !== "") {

            if (newPassword.value.length < 8) {
                setErrMsg("Password should be atleast 8 characeters long.");
                return;
            }

            if (newPassword.value !== confirmPassword.value) {
                setErrMsg("Confirm Password does not match");
                return;
            }

        }

        if (name.value.length < 3) {
            setErrMsg("Name should be 3 characters long.");
            return;
        }


        setIsBtnLoading(true);


        API.post("update-profile", {
            name: name.value,
            password: password.value,
            newPassword: newPassword.value,
            confirmPassword: confirmPassword.value,
        })
            .then((e) => {

                setOkMsg("Updated");
                setIsBtnLoading(false);

                password.value = "";
                newPassword.value = "";
                confirmPassword.value = "";

            })
            .catch((err) => {
                setErrMsg(err.message);
                setIsBtnLoading(false);
            });
    }

    return (

        <Main active="profile">

            {

                isLoading ?


                    <BigProgress />


                    :

                    <div className="p-4 lg:max-w-4xl lg:m-auto w-full">


                        <BackBox
                            path={'/dashboard'}
                            msg="Back To Dashboard"
                        />

                        <H3 className="mb-5 mt-5">
                            Profile Settings
                        </H3>

                        <H5 className="my-5">
                            General
                        </H5>


                        <form ref={formRef} className="w-full">

                            <Input

                                type="text"
                                label="Your Email"
                                name="email"
                                disabled={true}

                            />

                            <Input

                                type="text"
                                label="Your Name"
                                name="name"
                                important={true}

                            />

                         
                            <div className="border-b border-gray-300 dark:border-gray-600"></div>

                            <H5 className="my-5">
                                Password (Optional)
                            </H5>


                            <Input

                                type="password"
                                label="Current Password"
                                name="password"
                                autocomplete="one-time-code"

                            />

                            <Input

                                type="password"
                                label="New Password"
                                name="newPassword"
                                autocomplete="one-time-code"

                            />

                            <Input

                                type="password"
                                label="Retype New Password"
                                name="confirmPassword"
                                autocomplete="one-time-code"

                            />

                            {
                                errMsg && <div className="my-4"><span className="text-red-600">{errMsg}</span></div>
                            }

                            {
                                okMsg && <div className="my-4"><span className="text-green-600 dark:text-green-400">{okMsg}</span></div>
                            }


                            <Button onClick={onUpdate} isLoading={isBtnLoading} className={"mt-2 w-full"}>
                                Update
                            </Button>

                        </form>


                    </div>

            }


        </Main>

    );


}

export default Profile;