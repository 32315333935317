import Main from "../Components/Main";
import { H3, H2, DangerAlert, DashboardCard, APIProgress, LinearProgress, Button, ButtonWithoutColor } from "../Components/Basics";
import { useEffect, useRef, useState } from "react";
import API from "../Support/API";
import { RiSignalTowerFill, RiParkingBoxLine, RiTimeLine } from "react-icons/ri";
import { HiChatBubbleOvalLeft } from 'react-icons/hi2';
import dateFormatter from "dateformat";
import { Link, useNavigate, useParams } from "react-router-dom";
import lunaImage from "../Images/luna.png";
import { AiOutlineMessage } from "react-icons/ai";
import { BiChat } from "react-icons/bi";
import { GreenBadge, RedBadge, DefaultBadge, YellowBadge } from "../Components/Badges";
import { BackBox } from "../Components/Basics";

const ViewList = () => {

    const [isLoading, setIsLoading] = useState(true);

    const navigate = useNavigate();

    const [data, setData] = useState(null);

    const { id } = useParams();

    const [status, setStatus] = useState('');

    useEffect(() => {

        if (id === undefined) return;

        API.get("lists/view/" + id)
            .then((e) => {
                setData(e.data);
                setStatus(e.data.list.status);
                setIsLoading(false);
            })
            .catch((err) => {

                navigate("/404", { replace: true });
            });

    }, [id]);


    const formatNum = (number) => {
        if (number >= 1000000) {
            return (number / 1000000).toFixed(1) + 'M';
        } else if (number >= 1000) {
            return (number / 1000).toFixed(1) + 'K';
        } else {
            return parseInt(number)
        }
    }

    const dateFormat = (date) => {

        const now = new Date(date);

        const newDate = dateFormatter(now, "mmmm dd, yyyy");

        return newDate;
    }

    //convert minute hour days
    function convertMHD(minutes) {
        if (isNaN(minutes) || minutes < 0) {
            return "Invalid input";
        }

        if (minutes < 60) {
            return `${minutes} minute${minutes === 1 ? '' : 's'}`;
        } else if (minutes < 1440) {
            const hours = Math.floor(minutes / 60);
            return `${hours} hour${hours === 1 ? '' : 's'}`;
        } else {
            const days = Math.floor(minutes / 1440);
            return `${days} day${days === 1 ? '' : 's'}`;
        }
    }

    const [isBtnLoading, setIsBtnLoading] = useState(false);

    const updateStatus = () => {

        setIsBtnLoading(true);

        API.get('lists/pause/' + id)
            .then((e) => {
                window.location.reload();
                // setIsBtnLoading(false);
                // setStatus(e.listStatus)
            })
            .catch((err) => {
                setIsBtnLoading(false);
            });
    }


    return (

        <Main active="lists">

            {
                isLoading ?

                    <APIProgress />


                    :


                    <div className="max-w-7xl mx-auto px-3 mt-5">

                        <BackBox
                            path={'/lists'}
                            msg="Back To Lists"
                        />

                        <H3 className="mt-4">List Details</H3>


                        <div className="my-5">

                        {
                            (data.list.errorMsg !== null) &&
                            <div className="my-5">
                                <DangerAlert>{ data.list.errorMsg}</DangerAlert>
                            </div>
                        }


                            <div className="relative overflow-x-auto">
                                <table className="w-full text-sm text-left border dark:bg-gray-800 dark:border-gray-700 text-gray-500 dark:text-gray-400">



                                    <tbody>

                                        <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                            <th scope="row" className="px-6 py-4 font-medium text-gray-900 dark:text-white">
                                                Status
                                            </th>
                                            <td className="px-6 py-4 font-medium">
                                                {

                                                    status === 'active' ? <DefaultBadge text='Active' /> :

                                                        status === 'failed' ? <RedBadge text='Failed' /> :

                                                            status === 'completed' ? <GreenBadge text='Completed' /> :

                                                                status === 'pause' ? <YellowBadge text='Paused' /> : <DefaultBadge text='None' />
                                                }
                                            </td>

                                        </tr>

                                        <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                            <th scope="row" className="px-6 py-4 font-medium text-gray-900 dark:text-white">
                                                Name
                                            </th>
                                            <td className="px-6 py-4 font-medium">
                                                {data.list.name}
                                            </td>

                                        </tr>

                                        <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                            <th scope="row" className="px-6 py-4 font-medium text-gray-900 dark:text-white">
                                                Post Articles to Wordpress Site
                                            </th>
                                            <td className="px-6 py-4 font-medium">
                                                {data.list.postToWP === 1 ? 'Yes' : 'No'}
                                            </td>
                                        </tr>

                                        {
                                            data.list.postToWP === 1 &&


                                            <>
                                                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                                    <th scope="row" className="px-6 py-4 font-medium text-gray-900 dark:text-white">
                                                        Wordpress URL
                                                    </th>
                                                    <td className="px-6 py-4 font-medium">
                                                        {data.wpURL}
                                                    </td>

                                                </tr>

                                                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                                    <th scope="row" className="px-6 py-4 font-medium text-gray-900 dark:text-white">
                                                        Wordpress Category
                                                    </th>
                                                    <td className="px-6 py-4 font-medium">
                                                        {data.list.wpCategory}
                                                    </td>

                                                </tr>

                                                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                                    <th scope="row" className="px-6 py-4 font-medium text-gray-900 dark:text-white">
                                                        Wordpress Post Status
                                                    </th>
                                                    <td className="px-6 py-4 font-medium uppercase">
                                                        {data.list.wpStatus}
                                                    </td>

                                                </tr>
                                            </>


                                        }

                                        <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                            <th scope="row" className="px-6 py-4 font-medium text-gray-900 dark:text-white">
                                                Article Language
                                            </th>
                                            <td className="px-6 py-4 font-medium">
                                                {data.list.articleLang === null ? "English" : data.list.articleLang}
                                            </td>

                                        </tr>


                                        <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                            <th scope="row" className="px-6 py-4 font-medium text-gray-900 dark:text-white">
                                                Total Keywords
                                            </th>
                                            <td className="px-6 py-4 font-medium">
                                                {data.list.keywordsCount}
                                            </td>

                                        </tr>

                                        <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                            <th scope="row" className="px-6 py-4 font-medium text-gray-900 dark:text-white">
                                                Interval for each post
                                            </th>
                                            <td className="px-6 py-4 font-medium">
                                                {convertMHD(data.list.intervalMins)}
                                            </td>

                                        </tr>


                                    </tbody>
                                </table>
                            </div>


                            <div onClick={() => updateStatus()} className="flex w-full sm:w-36 my-5">
                                {
                                    (status !== 'pause' &&  status !== 'completed') ?

                                        <ButtonWithoutColor  isLoading={isBtnLoading} className={"mt-2 w-full bg-red-700 dark:bg-red-700 no-hover dark:hover:bg-red-800  hover:bg-red-700"}>
                                            Pause
                                        </ButtonWithoutColor>

                                        :
                                    
                                    status === 'pause'

                                    ?

                                        <ButtonWithoutColor isLoading={isBtnLoading} className={"mt-2 w-full bg-yellow-300 dark:bg-yellow-400 color-black hover:bg-yellow-300  dark:hover:bg-yellow-400"}>
                                            Resume
                                        </ButtonWithoutColor>

                                    :

                                    <></>
                                }
                            </div>



                        </div>


                        {

                            (data !== null && data.keywords.length > 0)

                            &&

                            <>

                                <H3 className="mt-7">Keywords</H3>

                                <div className="relative overflow-x-auto my-5">

                                    <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">

                                        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                            <tr>
                                                <th scope="col" className="px-6 py-3">
                                                    Keyword
                                                </th>
                                                <th scope="col" className="px-6 py-3">
                                                    Done
                                                </th>

                                            </tr>
                                        </thead>

                                        <tbody>

                                            {

                                                data.keywords.map((e) => {


                                                    return (
                                                        <tr key={e.id} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                                            <th scope="row" className="px-6 py-4 font-medium text-gray-900 dark:text-white">
                                                                {e.keyword}
                                                            </th>

                                                            <td className="px-6 py-4">
                                                                {
                                                                    e.done === 1 ?

                                                                        <GreenBadge
                                                                            text={'Yes'}
                                                                        />

                                                                        :

                                                                        <RedBadge text={'No'} />
                                                                }
                                                            </td>




                                                        </tr>
                                                    )


                                                })
                                            }



                                        </tbody>

                                    </table>

                                </div>

                            </>
                        }



                    </div>

            }




        </Main>

    );


}

export default ViewList;