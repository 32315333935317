import NavBarAuth from "../Components/NavBarAuth";
import { useEffect, useRef, useState } from "react";
import { Button, H2, Input, Link } from "../Components/Basics";
import { Navigate, useParams } from "react-router-dom";
import API from "../Support/API";
import Constants from "../Support/Constants";
import { BigProgress } from "../Components/Basics";
import NotFound from "./NotFound";

const FPReset = ({ isLoggedIn }) => {

    const formRef = useRef();

    const [errMsg, setErrMsg] = useState("");
    const [msg, setMsg] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const [tokenValid, setTokenValid] = useState(null);
    const [userEmail, setUserEmail] = useState(null);

    const { token } = useParams();

    useEffect(() => {

        if (token === undefined) return;

        API.get("fp/reset/" + token)
            .then((e) => {
                setTokenValid(true);
                setUserEmail(e.userEmail);
            })
            .catch((err) => {
                setTokenValid(false);
            });


    }, []);

    const updatePassword = () => {

        const { password, cPassword } = formRef.current;

        setErrMsg('');
        setIsLoading(true);

        API.post("fp/reset/" + token, {
            password: password.value,
            cPassword: cPassword.value,
        })
            .then((e) => {
                setMsg(e.msg);
                setIsLoading(false);
            })
            .catch((err) => {
                setErrMsg(err.message);
                setIsLoading(false);
            });
    }



    return (

        isLoggedIn ?

            <Navigate to={Constants.homeUrl} />

            :

            tokenValid === null ?


                <BigProgress />

                :

                <>

                    <NavBarAuth />

                    {

                        tokenValid === true

                            ?


                            <div className="app-container px-2 flex justify-center flex-col items-center">


                                <div className="border border-slate-300 dark:border-gray-600 px-10 py-20 rounded-lg w-full sm:w-full md:w-1/2 lg:w-1/3 xl:w-1/3 2xl:w-1/4">

                                    <H2>
                                        Password Reset
                                    </H2>


                                    <div className="mb-4">
                                        <span className="text-amber-800 dark:text-amber-600">
                                           Password change for the account with the email : {userEmail}
                                        </span>
                                    </div>

                                    {
                                        errMsg && <div className="mb-4"><span className="text-red-600">{errMsg}</span></div>
                                    }


                                    <form ref={formRef}>

                                        <Input

                                            type="password"
                                            label="New Password"
                                            name="password"

                                        />

                                        <Input

                                            type="password"
                                            label="Confirm Password"
                                            name="cPassword"

                                        />


                                        <div className="my-5 dark:text-white">
                                            <span className="mr-2">Back To</span>
                                            <Link to={"/login"}>
                                                Sign In
                                            </Link>
                                        </div>

                                        {
                                            msg ?

                                                <div className="my-4"><span className="text-green-600 dark:text-green-400">{msg}</span></div>

                                                :

                                                <Button isLoading={isLoading} onClick={updatePassword} className="w-full">
                                                    Update Password
                                                </Button>


                                        }


                                    </form>

                                </div>


                            </div>


                            :


                            <NotFound />

                    }

                </>
    );
}

export default FPReset;