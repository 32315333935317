import NavBarAuth from "../Components/NavBarAuth";
import { useRef, useState } from "react";
import { Button, H2, Input, Link } from "../Components/Basics";
import { Navigate } from "react-router-dom";
import API from "../Support/API";
import Constants from "../Support/Constants";
import { isEmail } from "../Support/FormValidator";


const FPLink = ({ isLoggedIn }) => {

    const formRef = useRef();

    const [errMsg, setErrMsg] = useState("");
    const [msg, setMsg] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const sendLink = () => {

        const { email } = formRef.current;

        if (!isEmail(email.value)) {
            setErrMsg('Please use a valid email address');
            return;
        }

        setErrMsg('');
        setIsLoading(true);

        API.post("fp/send", {
            email: email.value,
        })
            .then((e) => {
                setMsg(e.msg);
                setIsLoading(false);
            })
            .catch((err) => {
                setErrMsg(err.message);
                setIsLoading(false);
            });
    }



    return (

        isLoggedIn ?

            <Navigate to={Constants.homeUrl} />

            :

            <>
                <NavBarAuth />


                <div className="app-container px-2 flex justify-center flex-col items-center">


                    <div className="border border-slate-300 dark:border-gray-600 px-10 py-20 rounded-lg w-full sm:w-full md:w-1/2 lg:w-1/3 xl:w-1/3 2xl:w-1/4">

                        <H2>
                            Password Reset
                        </H2>

                        {
                            errMsg && <div className="mb-4"><span className="text-red-600">{errMsg}</span></div>
                        }

                        <form ref={formRef}>

                            <Input

                                type="email"
                                label="Enter your email address"
                                name="email"

                            />


                            <div className="my-5 dark:text-white">
                                <span className="mr-2">Back To</span>
                                <Link to={"/login"}>
                                    Sign In
                                </Link>
                            </div>

                            {
                                msg ? 
                                
                                <div className="my-4"><span className="text-green-600 dark:text-green-400">{msg}</span></div> 
                                
                                :

                                <Button isLoading={isLoading} onClick={sendLink} className="w-full">
                                    Send Link
                                </Button>


                            }


                        </form>

                    </div>


                </div>
            </>
    );
}

export default FPLink;