import Main from "../Components/Main";
import { H3, H2, DangerAlert, DashboardCard, APIProgress, LinearProgress } from "../Components/Basics";
import { useEffect, useRef, useState } from "react";
import API from "../Support/API";
import { RiSignalTowerFill, RiParkingBoxLine, RiTimeLine, RiEyeLine, RiDeleteBin7Line } from "react-icons/ri";
import dateFormatter from "dateformat";
import { Link } from "react-router-dom";
import { AiOutlineMessage } from "react-icons/ai";
import { BiChat } from "react-icons/bi";
import { GreenBadge, RedBadge, DefaultBadge, YellowBadge  } from "../Components/Badges";
import Constants from "../Support/Constants";

const Dashboard = () => {

    const [cardsValue, setCardsValue] = useState({
        totalWPSites: 0,
        totalLists: 0,
        credits: 0
    });

    const [isLoading, setIsLoading] = useState(true);

    const [lists, setLists] = useState(null);

    const [isEmailVerified, setIsEmailVerified] = useState(false);

    const formatNum = (number) => {
        if (number >= 1000000) {
            return (number / 1000000).toFixed(1) + 'M';
        } else if (number >= 1000) {
            return (number / 1000).toFixed(1) + 'K';
        } else {
            return parseInt(number)
        }
    }

    useEffect(() => {

        API.get('dashboard')
            .then((e) => {

                setCardsValue({
                    totalWPSites: e.data.totalWPSites,
                    totalLists: e.data.totalLists,
                    credits: e.data.credits,
                });

                setLists(e.data.recentLists);

                setIsEmailVerified(e.data.isEmailVerified);

                setIsLoading(false);

            })
            .catch((err) => {

            })

    }, []);

    const dateFormat = (date) => {

        const now = new Date(date);

        const newDate = dateFormatter(now, "mmmm dd, yyyy");

        return newDate;
    }


    return (

        <Main active="dashboard">

            {
                isLoading ?

                    <APIProgress />


                    :


                    <div className="max-w-6xl mx-auto px-3">

                        <H3 className="mt-4">Dashboard</H3>


                        {
                            isEmailVerified === 0 &&
                            <div className="my-5">
                                <DangerAlert>We have sent a verification link to your email. Please verify your email to continue using {Constants.appName}.</DangerAlert>
                            </div>
                        }



                        <div className="flex items-center justify-center flex-wrap w-full">

                            <DashboardCard

                                title={'WP Sites'}
                                value={formatNum(cardsValue.totalWPSites)}
                                icon={<AiOutlineMessage class="dark:text-white" size={25} />}

                            />

                            <DashboardCard

                                title={'Lists'}
                                value={formatNum(cardsValue.totalLists)}
                                icon={<BiChat class="dark:text-white" size={25} />}

                            />

                            <DashboardCard

                                title={'Credits'}
                                value={formatNum(cardsValue.credits)}
                                icon={<RiTimeLine class="dark:text-white" size={25} />}

                            />
                        </div>



                        {

                            lists !== null && lists.length > 0

                            &&

                            <>

                                <H3 className="mt-7">Recent Lists</H3>

                                <div className="relative overflow-x-auto my-5">

                                    <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">

                                        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                            <tr>
                                                <th scope="col" className="px-6 py-3">
                                                    Name
                                                </th>
                                                <th scope="col" className="px-6 py-3">
                                                    Status
                                                </th>
                                                <th scope="col" className="px-6 py-3">
                                                MODIFIED AT
                                                </th>
                                                <th scope="col" className="px-6 py-3">
                                                    Action
                                                </th>
                                            </tr>
                                        </thead>

                                        <tbody>

                                            {

                                            lists !== null && lists.map((e) => {

                                                    //if status is training, increase on training value

                                                    return (
                                                        <tr key={e.id} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                                            <th scope="row" className="px-6 py-4 font-medium text-gray-900 dark:text-white">
                                                                {e.name}
                                                            </th>

                                                            <td className="px-6 py-4">
                                                                <div>

                                                                    {

                                                                        e.status === 'active' ? <DefaultBadge text='Active' /> :

                                                                            e.status === 'failed' ? <RedBadge text='Failed' /> :

                                                                                e.status === 'completed' ? <GreenBadge text='Completed' /> :

                                                                                    e.status === 'pause' ? <YellowBadge text='Paused' /> : <DefaultBadge text='None' />
                                                                    }


                                                                </div>
                                                            </td>


                                                            <td className="px-6 py-4">
                                                                {dateFormat(e.updatedAt)}
                                                            </td>

                                                            <td className="px-6 py-4">

                                                                <div className="flex">

                                                                    <Link to={"/lists/view/" + e.id}><RiEyeLine color="#49a06f" className="mr-2" size={20} /></Link>

                                                                </div>

                                                            </td>

                                                            </tr>



                                                            )
                                                    

                                            })
                                            }



                                            </tbody>

                                    </table>

                                </div>

                            </>
                        }



                    </div>

            }




        </Main>

    );


}

export default Dashboard;