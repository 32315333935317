import Main from "../Components/Main";
import { Input, H3, H5, Button } from "../Components/Basics";
import { useEffect, useRef, useState } from "react";
import { isEmpty } from "../Support/FormValidator";
import API from "../Support/API";
import { BackBox } from "../Components/Basics";
import { useNavigate, useParams } from "react-router-dom";
import NotFound from "./NotFound";
import { BigProgress } from "../Components/Basics";

const EditWPSite = () => {

    const formRef = useRef();

    const [errMsg, setErrMsg] = useState("");
    const [okMsg, setOkMsg] = useState("");
    const [isBtnLoading, setIsBtnLoading] = useState(false);
    const [data, setData] = useState([]);

    const { id } = useParams();

    useEffect(() => {

        if (id === undefined) return;

        API.get('wp-sites/get/' + id)
            .then((e) => {
                setData(e.data);
            })
            .catch((err) => {
                setData(null);

            });


    }, [id]);

    const onUpdate = () => {

        setErrMsg("");
        setOkMsg("");

        const { name, password, url, username } = formRef.current;

        if (name.value.length === 0) {
            setErrMsg("Please enter website name.");
            return;
        }

        if (url.value.length === 0) {
            setErrMsg("Please enter wordpress url.");
            return;
        }

        if (password.value.length === 0) {
            setErrMsg("Please enter wordpress app password.");
        }

        if (username.value.length === 0) {
            setErrMsg("Please enter wordpress username.");
            return;
        }

        setIsBtnLoading(true);

        API.post("wp-sites/update", {
            id: id,
            name: name.value,
            password: password.value,
            username: username.value,
            url: url.value
        })
            .then((e) => {

                setIsBtnLoading(false);
                setOkMsg(e.msg);

            })
            .catch((err) => {
                setErrMsg(err.message);
                setIsBtnLoading(false);
            });
    }

    return (

        data === null

            ?

            <NotFound />

            :

            <Main active="wpSites" >

                {

                    data.length === 0 ?


                        <BigProgress />


                    :


                <div className="p-4 lg:max-w-3xl lg:m-auto w-full">

                    <BackBox
                        path={'/wp-sites'}
                        msg="Back To Wordpress Websites"
                    />

                    <H3 className="mb-5 mt-5">
                        Update Wordpress Website
                    </H3>

                    <form ref={formRef} className="w-full">


                        <Input

                            type="text"
                            label="Name"
                            name="name"
                            placeholder={"My Tech website"}
                            important={true}
                            value={data.name}

                        />
                        <Input

                            type="text"
                            label="Wordpress URL"
                            name="url"
                            placeholder={"https://example.com"}
                            important={true}
                            value={data.url}

                        />

                        <Input

                            type="text"
                            label="Wordpress Username"
                            name="username"
                            important={true}
                            autocomplete="one-time-code"
                            value={data.username}

                        />

                        <Input

                            type="text"
                            label="Wordpress App Password"
                            name="password"
                            important={true}
                            placeholder={"IKIy mMPF Snik pSuQ t4ZX I5ow"}
                            autocomplete="one-time-code"
                            value={data.password}

                        />

                        <div className="dark:text-white mb-4 text-sm -mt-3">Wordpress App Password is different than the admin panel password.
                            Read this guide on how to get wordpress app password : <a className="dark:text-blue-300 text-blue-600 inline" href="https://www.paidmembershipspro.com/create-application-password-wordpress/" target="_blank">Guide</a></div>

                        <div className="dark:text-white mb-4 mt-7 text-sm -mt-3">Note: We will create a test post on your WordPress website and save it as a draft to verify the details. The test post will be deleted immediately afterward.</div>



                        {
                            errMsg && <div className="my-4"><span className="text-red-600">{errMsg}</span></div>
                        }

                        {
                            okMsg && <div className="my-4"><span className="text-green-600 dark:text-green-400">{okMsg}</span></div>
                        }


                        <Button onClick={onUpdate} isLoading={isBtnLoading} className={"mt-2 w-full"}>
                            Update
                        </Button>

                    </form>


                </div>

                
            }



            </Main >

    );


}

export default EditWPSite;