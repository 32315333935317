import Main from "../Components/Main";
import { Input, H3, H5, Button } from "../Components/Basics";
import { useEffect, useRef, useState } from "react";
import { isEmpty } from "../Support/FormValidator";
import API from "../Support/API";
import { BackBox } from "../Components/Basics";
import { useNavigate, useParams } from "react-router-dom";
import NotFound from "./NotFound";
import { BigProgress } from "../Components/Basics";

const ViewArticle = () => {

    const [data, setData] = useState([]);

    const { id } = useParams();

    useEffect(() => {

        if (id === undefined) return;

        API.get('articles/get/' + id)
            .then((e) => {
                setData(e.data);
            })
            .catch((err) => {
                setData(null);

            });


    }, [id]);


    return (

        data === null

            ?

            <NotFound />

            :

            <Main active="articles" >

                {

                    data.length === 0 ?


                        <BigProgress />


                    :


                <div className="p-4 lg:max-w-4xl lg:m-auto w-full">

                    <BackBox
                        path={'/articles'}
                        msg="Back To Articles"
                    />

                    <div className="prose dark:prose-invert max-w-none prose-headings:mb-5" dangerouslySetInnerHTML={{__html : data.article}} />
                
                </div>

                
            }



            </Main >

    );


}

export default ViewArticle;