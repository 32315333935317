import { H2, Link } from "../Components/Basics";
import NavBarAuth from "../Components/NavBarAuth";
import { useEffect, useRef, useState } from "react";
import API from "../Support/API";
import { BigProgress } from "../Components/Basics";
import { useParams } from "react-router-dom";
import NotFound from "./NotFound";

const VerifyEmail = () => {

    const { token } = useParams();
    const [isVerified, setIsVerified] = useState(null);
    const init = useRef(true);

    useEffect(() => {

        if (init.current) {

            API.post("verify-email", {
                token: token
            })
                .then((e) => {
                    setIsVerified(true);
                })
                .catch((err) => {
                    setIsVerified(false);
                });

            init.current = false;
        }

    }, []);

    return (

        isVerified === null ?

            <BigProgress />

            :

            isVerified === true

                ?

                <>

                    <NavBarAuth />


                    <div className="app-container px-3 flex justify-center flex-col items-center">

                        <div className="border border-slate-300 dark:border-gray-600 px-10 py-20 rounded-lg sm:w-1/3 w-full">

                            <H2>Email Verified</H2>

                            <span className="dark:text-white">Thank you. Your email has been verified. Go back to <Link className="font-bold" to={'/'}>Home</Link></span>

                        </div>

                    </div>

                </>

                :

                <NotFound />

    )

}

export default VerifyEmail;