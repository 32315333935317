import { useEffect, useRef, useState } from "react";
import { Modal } from "flowbite";

const NoticeModal = ({ show, notice }) => {

    const [modal, setModal] = useState();
    const modalContainer = useRef();

    useEffect(() => {

        // set the modal menu element
        const targetEl = document.getElementById('notice-modal');

        // options with default values
        const options = {
            placement: 'center',
            backdrop: 'dynamic',
            backdropClasses: 'fixed inset-0 z-40 backdrop-blur-sm bg-opacity-50 bg-black dark:bg-gray-900 dark:bg-opacity-80',
            closable: true,
        };

        let m = new Modal(targetEl, options);
        setModal(m);


    }, []);

    useEffect(() => {

        if (show === 0) {
            return;
        }

        modalContainer.current.classList.add("modal-open");
        modalContainer.current.classList.remove("modal-close");

        modal.show();

    }, [show]);

    const closeModal = () => {

        modalContainer.current.classList.remove("modal-open");
        modalContainer.current.classList.add("modal-close");

        let to = setTimeout(() => {
            modal.hide();
        }, 220);
    }



    return (

        <div ref={modalContainer} id="notice-modal" tabindex="-1" aria-hidden="true" className="modal-open fixed top-0 left-0 right-0 z-50 hidden p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full">
            <div className="relative w-full max-w-2xl max-h-full">
                
                <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                    
                    <div className="flex items-start justify-between p-4 border-b rounded-t dark:border-gray-600">
                        <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                           {notice.title}
                        </h3>
                        <button type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="defaultModal">
                            <svg onClick={closeModal} aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                            <span className="sr-only">Close modal</span>
                        </button>
                    </div>
                    
                    <div className="p-6 space-y-6">
                        <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                           {notice.msg}
                        </p>
                    </div>
                    
                    <div className="flex items-center p-6 space-x-2 border-t border-gray-200 rounded-b dark:border-gray-600">
                        <button onClick={closeModal} data-modal-hide="defaultModal" type="button" className="text-white bg-amber-800 hover:bg-amber-900 font-medium rounded text-sm px-5 py-2.5 text-center dark:bg-amber-800 dark:hover:bg-amber-900">Got it</button>
                       
                    </div>
                </div>
            </div>
        </div>
    )

}

export default NoticeModal;