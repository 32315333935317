import { useEffect, useState } from "react";
import { BigProgress } from "../Components/Basics";
import { Navigate, useNavigate } from "react-router-dom";
import API from "../Support/API";

const VerifyPayment = () => {

    const [isLoading, setIsLoading] = useState(true);

    const navigate = useNavigate();

    useEffect(() => {

        API.get("pay/verify")
        .then((e) => {
            setIsLoading(false);
        })
        .catch((err) => {
            setIsLoading(false);
        });

    },[]);

    return (

        isLoading
        
        ? 

        <BigProgress />

        :

        <Navigate to="/credits" />
    )
}

export default VerifyPayment;