import { H2, Link } from "../Components/Basics";
import NavBarAuth from "../Components/NavBarAuth";

const NotFound = () => {

    return (

        <>

            <NavBarAuth />


            <div className="app-container px-2 flex justify-center flex-col items-center">

                <div className="border border-slate-300 dark:border-gray-600 px-10 py-20 rounded-lg sm:w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/3 2xl:w-1/4">

                   <H2>404</H2>

                   <span className="dark:text-white">The page you are trying to access could not be found. Go back to <Link className="font-bold" to={'/'}>Home</Link></span>

                </div>

            </div>

        </>

    )

}

export default NotFound;