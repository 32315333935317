const Constants = {

    //without end slash /
    //apiUrl: "https://api.andbam.agency",

    //apiUrl : "http://api.blogherassist.com",

    //apiUrl: "http://localhost:8001",

    apiUrl : "https://api-blogherassist.centence.net",

    appName: "blogherassist",

    homeUrl : "/dashboard"
}

export default Constants;