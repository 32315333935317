import Main from "../Components/Main";
import { H3, FullCard, APIProgress, BackBox, Button } from "../Components/Basics";
import { GreenBadge } from "../Components/Badges";
import { useEffect, useRef, useState } from "react";
import API from "../Support/API";
import { RiCoinLine } from "react-icons/ri";
import dateFormatter from "dateformat";
import CreditTable from "../Components/CreditTable";
import LoadingModal from "../Components/Modals/LoadingModal";
import NoticeModal from "../Components/Modals/NoticeModal";

const Credits = () => {

    const [isLoading, setIsLoading] = useState(true);

    const [creditHistory, setCreditHistory] = useState(null);

    const [creditPacks, setCreditPacks] = useState([]);

    const [lifetimePacks, setLifetimePacks] = useState([]);

    const [credits, setCredits] = useState(0);

    const [showLoadingModel, setShowLoadingModal] = useState(0);

    const [showNoticeModel, setShowNoticeModal] = useState({
        notice : {
            title : '',
            msg : ''
        },
        show : 0
    });

    const formatNum = (number) => {
        if (number >= 1000000) {
            return (number / 1000000).toFixed(1) + 'M';
        } else if (number >= 1000) {
            return (number / 1000).toFixed(1) + 'K';
        } else {
            return parseInt(number)
        }
    }

    useEffect(() => {

        API.get('credits')
            .then((e) => {

                setCredits(e.data.credits);

                setCreditHistory(e.data.creditHistory);

                setCreditPacks(e.data.creditPacks)

                setLifetimePacks(e.data.lifetimePacks)

                setIsLoading(false);

            })
            .catch((err) => {

            })

    }, []);

    const dateFormat = (date) => {

        const now = new Date(date);

        const newDate = dateFormatter(now, "mmmm dd, yyyy");

        return newDate;
    }

    const onCreditPackClick = (id, isLifeTime) => {

        setShowLoadingModal((e) => e+1);

        const lifetime = isLifeTime === true ? 1 : 0;

        API.get(`credits/pay/${id}/${lifetime}`)
        .then((e) => {
            window.location.href = e.link;
        })
        .catch((err) => {
           
            setShowLoadingModal('close');

            setTimeout(() => {
                setShowLoadingModal(0);

            }, 200);

            setTimeout(() => {
                setShowNoticeModal((e) => {
                    return {
                        notice : {
                            title : 'Error',
                            msg : err.message
                        },
                        show : e.show + 1
                    }
                });
            }, 600);

           
        })
      
    }


    return (

        <Main active="credits">

        <LoadingModal show={showLoadingModel} />

        <NoticeModal show={showNoticeModel.show} notice={showNoticeModel.notice}  />

            {
                isLoading ?

                    <APIProgress />


                    :


                    <div className="max-w-6xl mx-auto p-4">

                        <BackBox
                            path={'/dashboard'}
                            msg="Back To Dashboard"
                        />

                        <H3 className="mt-4 mb-2 sm:mb-0">Credits</H3>


                        <div className="flex items-center justify-center flex-wrap w-full">

                            <FullCard

                                title={'Your Credits'}
                                value={formatNum(credits)}
                                icon={<RiCoinLine class="dark:text-white" size={25} />}

                            />

                        </div>



                        {

                            creditHistory !== null && creditHistory.length > 0

                            &&

                            <>

                                <H3 className="mt-7">Recent Credits</H3>

                                <div className="relative overflow-x-auto my-5">

                                    <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">

                                        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                            <tr>
                                                <th scope="col" className="px-6 py-3">
                                                    Credits From
                                                </th>
                                                <th scope="col" className="px-6 py-3">
                                                    Credits
                                                </th>
                                                <th scope="col" className="px-6 py-3">
                                                    Expire At
                                                </th>

                                            </tr>
                                        </thead>

                                        <tbody>

                                            {

                                                creditHistory !== null && creditHistory.map((e) => {

                                                    //if status is training, increase on training value

                                                    return (
                                                        <tr key={e.id} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                                            <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                                                {e.name}
                                                            </th>

                                                            <td className="px-6 py-4">
                                                                <GreenBadge
                                                                    text={formatNum(e.credits)}
                                                                />
                                                            </td>


                                                            <td className="px-6 py-4">
                                                                {dateFormat(e.expireAt)}
                                                            </td>


                                                        </tr>
                                                    )


                                                })
                                            }



                                        </tbody>

                                    </table>

                                </div>

                            </>
                        }

                        <div>

                            <div>
                                <H3 className="mt-7">Buy Credits</H3>

                                <div className="w-full flex items-center flex-wrap">

                                    {

                                        creditPacks.map((e) => {

                                            return (

                                                <CreditTable

                                                    id={e.id}
                                                    name={e.name}
                                                    credits={e.credits}
                                                    days={e.expireDays}
                                                    price={e.price}
                                                    isLifeTime={false}
                                                    onBtnClick={onCreditPackClick}

                                                />
                                            )

                                        })

                                    }


                                </div>
                            </div>

                            {

                                lifetimePacks.length > 0 &&

                                <div>
                                    <H3 className="mt-7">Lifetime Deals</H3>

                                    <div className="w-full flex items-center flex-wrap">

                                        {

                                            lifetimePacks.map((e) => {

                                                return (

                                                    <CreditTable

                                                        id={e.id}
                                                        name={e.name}
                                                        credits={e.credits}
                                                        days={e.expireDays}
                                                        price={e.price}
                                                        isLifeTime={true}
                                                        onBtnClick={onCreditPackClick}

                                                    />
                                                )

                                            })

                                        }


                                    </div>
                                </div>
                            }


                        </div>



                    </div>

            }




        </Main>

    );


}

export default Credits;