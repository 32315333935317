import { BsCheckCircle } from "react-icons/bs";


const CreditTable = ({ id, name, price, credits, days, isLifeTime = false, onBtnClick = () => null }) => {

    return (
        <div className="w-full lg:mr-3 lg:mb-3 lg:w-64 sm:m-8 mt-4 mb-0 sm:ml-0 block flex flex-wrap items-center p-6 bg-white rounded-lg border-slate-200 border dark:bg-gray-800 dark:border-gray-600">

            <div className="flex justify-center items-center flex-col w-full">

                <div className="mb-9">

                    <p className="font-normal text-gray-700 dark:text-gray-400 text-center">{name}</p>
                    <h1 className="mb-2 text-4xl tracking-wide font-semibold tracking-tight text-gray-900 dark:text-white mt-7 text-center">{price} $</h1>

                    <ul className="mt-7">
                        <li>
                            <div className="flex items-center mb-2">
                                <BsCheckCircle class="dark:text-white mr-2" size={13} />
                                <span className="font-normal text-gray-800 dark:text-gray-300 text-center">Quality Articles</span>
                            </div>
                        </li>
                        <li>
                            <div className="flex items-center mb-2">
                                <BsCheckCircle class="dark:text-white mr-2" size={13} />
                                <span className="font-normal text-gray-800 dark:text-gray-300 text-center">Auto posting on WP</span>
                            </div>
                        </li>
                        <li>
                            <div className="flex items-center mb-2">
                                <BsCheckCircle class="dark:text-white mr-2" size={13} />
                                <span className="font-normal text-gray-800 dark:text-gray-300 text-center">{credits} Credits</span>
                            </div>
                        </li>
                        <li>
                            <div className="flex items-center mb-2">
                                <BsCheckCircle class="dark:text-white mr-2" size={13} />
                                <span className="font-normal text-gray-800 dark:text-gray-300 text-center">{isLifeTime === false ? `For ${days} Days` : "Renews every month" }</span>
                            </div>
                        </li>
                    </ul>

                </div>
                <button onClick={() => onBtnClick(id, isLifeTime)} type="button" className={`text-white bg-green-500 hover:bg-green-600 font-medium rounded-xl text-sm px-5 py-2.5 text-center w-full`}>
                    Buy Now
                </button>
            </div>

        </div>



    )
}

export default CreditTable;