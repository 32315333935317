import logo from './logo.svg';
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useLocation
} from "react-router-dom";

import LoadingBar from 'react-top-loading-bar';

import axios from 'axios';
import { useEffect, useState, useRef } from 'react';
import Constants from './Support/Constants';
import Protected from './Support/Protected';
import { BigProgress } from './Components/Basics';


import Login from './Pages/Login';
import Register from './Pages/Register';
import Profile from './Pages/Profile';
import Logout from './Pages/Logout';
import NotFound from './Pages/NotFound';
import Dashboard from './Pages/Dashboard';
import VerifyEmail from './Pages/VerifyEmail';
import FPLink from './Pages/FPLink';
import FPReset from './Pages/FPReset';


import AddWPSite from './Pages/AddWPSite';
import WPSites from './Pages/WPSites';
import EditWPSite from './Pages/EditWPSite';
import AddList from './Pages/AddList';
import Lists from './Pages/Lists';
import ViewList from './Pages/ViewList';
import Credits from './Pages/Credits';
import VerifyPayment from './Pages/VerifyPayment';
import Articles from './Pages/Articles';
import ViewArticle from './Pages/ViewArticle';
import Landing from './Pages/Landing';

function App() {

  const location = useLocation();

  const loadingRef = useRef(null);

  const [isLoggedIn, setIsLoggedIn] = useState(null);

  useEffect(() => {

    axios.get(Constants.apiUrl + `/login-checker`, {
      withCredentials: true
    })
      .then((e) => {

        global.apiKey = e.data.apiKey;

        if (e.data.isLoggedIn === true) {
          //window.location.replace("/projects");
          setIsLoggedIn(true);
        } else {
          setIsLoggedIn(false);
        }

      })
      .catch((err) => {

        setIsLoggedIn(false);

      });

  }, []);

  const updateLoggedIn = () => setIsLoggedIn(false);


  useEffect(() => {

    loadingRef.current.continuousStart();

    setTimeout(() => {
      loadingRef.current.complete();
    }, 300);


  }, [location]);

  return (

    isLoggedIn == null

      ?

      <>

        <LoadingBar color='#49a06f' height={4} ref={loadingRef} />
        <div className="h-screen">
          <BigProgress />
        </div>

      </>


      :

      <>

        <LoadingBar color='#49a06f' height={4} ref={loadingRef} />

        <Routes>

          <Route exact path='/' element={<Landing />} />

          <Route exact path='login' element={<Login isLoggedIn={isLoggedIn} />} />

          <Route exact path='register' element={<Register isLoggedIn={isLoggedIn} />} />

          <Route exact path='verify-email/:token' element={<VerifyEmail />} />

          <Route exact path='forgot-password/link' element={<FPLink isLoggedIn={isLoggedIn} />} />

          <Route exact path='forgot-password/reset/:token' element={<FPReset isLoggedIn={isLoggedIn} />} />

          <Route exact path="dashboard"
            element={
              <Protected isLoggedIn={isLoggedIn}><Dashboard /></Protected>
            }
          />


          <Route exact path="profile"
            element={
              <Protected isLoggedIn={isLoggedIn}><Profile /></Protected>
            }
          />

          <Route exact path="logout"
            element={
              <Protected isLoggedIn={isLoggedIn}><Logout updateLoggedIn={updateLoggedIn} /></Protected>
            }
          />

          <Route exact path="wp-sites/add"
            element={
              <Protected isLoggedIn={isLoggedIn}><AddWPSite /></Protected>
            }
          />


          <Route exact path="wp-sites/edit/:id"
            element={
              <Protected isLoggedIn={isLoggedIn}><EditWPSite /></Protected>
            }
          />

          <Route exact path="wp-sites"
            element={
              <Protected isLoggedIn={isLoggedIn}><WPSites /></Protected>
            }
          />

          <Route exact path="lists/add"
            element={
              <Protected isLoggedIn={isLoggedIn}><AddList /></Protected>
            }
          />

          <Route exact path="lists/view/:id"
            element={
              <Protected isLoggedIn={isLoggedIn}><ViewList /></Protected>
            }
          />

          <Route exact path="lists"
            element={
              <Protected isLoggedIn={isLoggedIn}><Lists /></Protected>
            }
          />

          <Route exact path="credits"
            element={
              <Protected isLoggedIn={isLoggedIn}><Credits /></Protected>
            }
          />

          <Route exact path="pay/verify"
            element={
              <Protected isLoggedIn={isLoggedIn}><VerifyPayment /></Protected>
            }
          />

          <Route exact path="articles"
            element={
              <Protected isLoggedIn={isLoggedIn}><Articles /></Protected>
            }
          />

          <Route exact path="articles/view/:id"
            element={
              <Protected isLoggedIn={isLoggedIn}><ViewArticle /></Protected>
            }
          />


          <Route path='*' element={<NotFound />} />


        </Routes>

      </>

  );
}

export default App;
